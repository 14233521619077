<template>
  <div>
    <section>
      <v-parallax src="/images/horizon.jpg" class="horizon" height="700">
        <v-container class="fill-height">
          <v-col>
            <h1 class="mb-4 title--bigShadow text-center">
              Marion & Charles-Antoine
            </h1>
          </v-col>
        </v-container>
      </v-parallax>
    </section>

    <section>
      <v-container>
        <v-col xs12 sm4 class="my-3">
          <v-container align="center" class="my-6 py-6">
            <p class="subheading my-8">
              Nous nous marions le <b><u>vendredi</u></b>
            </p>
            <h1 class="heading">28 JUIN 2024</h1>
            <p class="subheading my-8"></p>
          </v-container>
        </v-col>
        <v-col>
          <v-container align="center">
            <v-img src="/images/caresse.jpg" class="center" width="50%"></v-img>
          </v-container>
        </v-col>
        <v-col xs12 sm4 class="my-8 text-center">
          <v-row>
            <v-col cols="2"> </v-col>
            <v-col cols="8">
              <h2>Bienvenue sur notre site de mariage !</h2>
              <p class="my-4">
                Ca y est, après 4 mois d'énigmes et de codes à déchiffrer, nous
                pouvons enfin nous marier !
                <br />
                Nous sommes vraiment au comble du bonheur et avons hâte de
                partager ce moment avec vous tous. En attendant le jour J, nous
                avons essayé de rassembler sur ce site toutes les informations
                pratiques pour préparer au mieux votre venue !
                <br />
                Un détail important, dans la section
                <a href="/confirmation">Confirmation</a> vous pouvez confirmer
                ou non votre venue. Merci de nous la communiquer au plus vite
                afin de faciliter notre organisation. Nous avons hâte de tous
                vous retrouver !
              </p>
            </v-col>
            <v-col cols="2"> </v-col>
          </v-row>
        </v-col>
      </v-container>
    </section>

    <section>
      <v-parallax src="/images/watches.jpg" height="700"> </v-parallax>
    </section>
  </div>
</template>

<script setup lang="ts">
useHead({ title: "Accueil" });
</script>

<style scoped>
.heading {
  font-size: 60px;
  font-weight: 300;
}
.subheading {
  font-size: 24px;
  font-weight: 600;
}
.title--bigShadow {
  font-weight: 900;
  text-shadow: 3px 2px #000000;
  color: rgb(255, 255, 240);
  font-size: 61px;
}
.title--smallShadow {
  font-weight: 900;
  text-shadow: 2px 2px #000000;
}
</style>

<style>
.horizon > .v-img__img {
  object-position: 50% 57%;
}
</style>
